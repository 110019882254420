import React from "react";

import "./logo.scss";

export const Logo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 262.7 113.4" className="logo" {...props}>
    <path fill="#69AA06" d="M0 28.3h79.4V85H0z" />
    <path fill="#447B0E" d="M0 28.3L62.4 0v28.3z" />
    <path fill="#9DD600" d="M79.4 28.3L17 56.7v56.7L79.4 85z" />
    <g>
      <path d="M124.4 46.4l-.9-6.1c-.2-.9-.4-1.2-.9-1.4-1.2-.4-3-.8-5.5-.8-5.6 0-8.4 2.5-8.4 6.4 0 4.9 5.1 6.3 10.8 8 7.1 2.2 15.1 4.9 15.1 16.1 0 12.3-9.6 17.1-21.1 17.1-6.8 0-14.3-1.7-17.2-3.1V68h8.4l1.5 7.1c.1.9.3 1.1 1.3 1.4.9.2 3.4.8 6.3.8 6.1 0 9.4-2.4 9.4-7 0-4.8-4.3-6.3-9.3-7.7-7.4-2.1-16.6-4.1-16.6-16.2 0-11.5 8.7-16.9 20.1-16.9 6.4 0 12 1.6 15.4 3v14.6l-8.4-.7zM165.1 84.8V60.4c0-2.2-.2-6.4-4-6.4-2.8 0-5.9 1.4-7.8 2.8v20.1c0 1.2.1 1.3 1.2 1.5l3.6.5v5.9h-20.7v-5.9l3.4-.5c1-.1 1.2-.4 1.2-1.5V37.3c0-.8-.2-1.4-1-1.6l-4.1-1.4.8-6.1h15.4v21.2l.4.1c3.4-2.8 7.3-4.5 12-4.5 8 0 10.9 4.3 10.9 12.1v19.7c0 1.2.1 1.3 1.2 1.5l3.7.5v5.9h-16.2zM184.4 84.8v-5.9l3.8-.5c1-.1 1.2-.4 1.2-1.5v-22c0-.8-.2-1.3-1-1.6l-4-1.4.8-6.1h15.5v31.1c0 1.2.1 1.3 1.2 1.5l3.8.5v5.9h-21.3zm9.6-45.1c-4.3 0-6.8-2.5-6.8-6.7s2.5-6.6 6.8-6.6 6.8 2.3 6.8 6.6-2.4 6.7-6.8 6.7zM262.7 82.7c-3.2 1.6-7.9 3-12.2 3-8.9 0-12.3-3.6-12.3-12.1V54c0-.5 0-.8-.6-.8h-12.1v23.3c0 1.4.2 1.4 1.4 1.6l5.2.8v5.9h-23.3v-5.9l4-.5c1.3-.1 1.3-.4 1.3-1.8V54c0-.5 0-.8-.6-.8h-5v-7.5h5.6V42c0-11.1 6.4-14.7 18.7-14.7 9.3 0 15.8 1.6 15.9 11.8l-10.6 1.3c0-4.6-1-5.8-5.8-5.8-5 0-6.8 1.5-6.8 5.8V45c0 .6.1.8.8.8h34.3v7.5h-11.2v17.9c0 4.4 1 6.1 5.1 6.1 2.1 0 4.3-.5 6.1-1.2l2.1 6.6z" />
    </g>
  </svg>
);
