import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Logo } from "../logo/logo";

import "./footer.scss";

export const Footer = props => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      municipalityInfoJson {
        sourceText
        formatUpdatedOn
      }
    }
  `);

  return (
    <footer className="footer" {...props}>
      <div className="footer__inner">
        <div>
          <a href="https://kommunaltilgaengelighed.dk">kommunaltilgaengelighed.dk</a> er et{" "}
          <a
            className="footer__logo-link"
            href="https://shiftcph.dk"
            aria-label="Gå til shiftcph.dk"
          >
            <Logo className="footer__logo" />
          </a>{" "}
          projekt.
          <p id="source-text">
            <small>
              Befolkningsdata er hentet fra {data.municipalityInfoJson.sourceText} d.{" "}
              {data.municipalityInfoJson.formatUpdatedOn}
            </small>
          </p>
        </div>
        <div>&copy; 2020.</div>
      </div>
    </footer>
  );
};
