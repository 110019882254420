import { Link } from "gatsby";
import React from "react";

import "./header.scss";

const Header = () => {
  return (
    <header className="header">
      <div className="header__inner">
        <Link to="/" className="header__home-link">
          <h1>Kommunal tilgængelighed</h1>
        </Link>

        <Link to="/oversigt">Oversigt over kommuner</Link>
      </div>
    </header>
  );
};

export default Header;
